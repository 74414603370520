<template>
	<div class="notice_popup prj_itv" :class="type == '1' ? 'input' : ''">
		<div class="close">
			<img src="/images/close.png" alt="닫기" @click="$emit('close')"/>
		</div>
		<img src="/images/interview.png" alt="프로젝트인터뷰"/><div class="tit">프로젝트 인터뷰 요청하기</div>
		
		<template v-if="type == '1'">
			<div class="itv_date">
				인터뷰 요청 일/시<br/>
				<SelectDate type="date" v-model="interviewReq.date" to="M3" title="일" /><nbsp/>
				<div class="select_wrap">
					<select v-model="interviewReq.hour">
						<option value="99" selected disabled hidden>시</option>
						<option v-for="item in 24" :key="item" :value="item-1">{{String(item-1).length == 1 ? '0' + String(item-1) : String(item-1)}}:00</option>
					</select> 
				</div>
			</div>
			<!-- <div class="member">요청할 기술인재 <tec-summary tagName="span" class="name" v-model="mberInfo" :tecMberSeq="param.mberSeq" :corpNm="param.corpNm"/></div> -->
			<div class="member"><tec-summary tagName="span" class="name" v-model="mberInfo" :tecMberSeq="param.mberSeq" /></div>
			<div class="select-btn">
				<div class="btn" @click="$emit('close')">취소</div>
				<div class="btn" @click="next()">다음 &#9654;</div>
			</div>
		</template>

		<template v-else>
			<div class="prj_outline">
				<div class="prj_name">'{{projectInfo.projectNm}}'</div>
				<div class="outline" style="display: inline-flex;">
					<div class="label ivl02">수 행 사</div>
					<div style="display: inline-flex; width: 560px; align-items: center;">
						<div class="value" style="white-space: nowrap;">{{projectInfo.corpNm}}</div>					
						<div class="value location">
							<img src="/images/location.png" alt="위치"/> {{projectInfo.projectLocCont}}
						</div>
					</div>
				</div>
				<div class="outline">
					<div class="label ivl02">담 당 자</div><div class="value">{{projectInfo.mberNm}}</div>
				</div>
				<div class="outline">
					<div class="label">참 여 기 간</div><div class="value">{{projectInfo.joinTermStartYyyymmdd | date('yyyy.MM.dd')}} - {{projectInfo.joinTermEndYyyymmdd | date('yyyy.MM.dd')}}</div>
				</div>
				<div class="outline">
					<div class="label">모 집 분 야</div><div class="value">{{projectInfo.workCont}}</div>
				</div>
				<div class="outline">
					<div class="label ivl03" style="margin-right: -3px;">개요</div>
					<div class="value text" style="max-width: 560px;">{{projectInfo.projectDtlDesc}}</div>
				</div>
				<div class="itv_date">
					인터뷰 요청 일/시 <p>{{interviewReq.date | date('yyyy.MM.dd')}} / {{interviewReq.hour > 11 ? 'PM' : 'AM'}} {{String(interviewReq.hour).length == 1 ? '0' + String(interviewReq.hour) : String(interviewReq.hour)}}:00</p>
				</div>
			</div>
			<!-- <div class="member" style="margin-left: -10px;"><tec-summary tagName="span" class="name" :value="mberInfo" :corpNm="param.corpNm"/> 님에게 위 프로젝트에 대한 인터뷰를 요청하시겠습니까?</div> -->
			<div class="member" style="margin-left: -10px;"><tec-summary tagName="span" class="name" :value="mberInfo"/></div>
			<div class="select-btn">
				<div class="btn" @click="type = '1'">&#9664; 이전</div>
				<div class="btn" @click="interviewReqEnd()">요청완료</div>
			</div>
		</template>

	</div>
</template>

<script>
import tecSummary from "@/components/highpro/TecSummary.vue";

export default {
	props : {
		param : Object
	},
	data() {
		return {
			data : this.param,
			mberInfo : {},
			interviewReq : {
				date : '',
				hour : '99'
			},

			projectInfo : {},

			type : '1',
		}
	},

	components : {
		tecSummary
	},

	mounted() {
		//console.log('PRJ911P01', 'mounted', this.param);
		this.getTecMberPutProjectInfo()
	},

	methods : {
		getTecMberPutProjectInfo() {
			this.$.httpPost('/api/prj/adm/getTecMberPutProjectInfo', {projectSeq:this.data.projectSeq, projectRecrFieldSeq:this.data.projectRecrFieldSeq})
				.then(res => {
					//console.log('getTecMberPutProjectInfo', res);
					this.projectInfo = res.data;
					this.$emit('updated');

					//this.type = '2';
				}).catch(this.$.httpErrorCommon);
		},

		next() {
			//console.log('next date',this.interviewReq.date);
			//console.log('next hour',this.interviewReq.hour);

			if(this.interviewReq.date == '' || this.interviewReq.hour == '99') {
				alert('인터뷰 요청 일/시를 선택해야 합니다.');
				return false;
			}

			this.type = '2';
			this.$emit('updated');
		},

		// 인터뷰 요청 완료
		interviewReqEnd() {
			var param = this.interviewReq;
			param.tecMberSeq = this.data.mberSeq;
			param.interviewReqSeq = this.data.interviewReqSeq;
			param.projectRecrFieldSeq = this.data.projectRecrFieldSeq;

			// 알림용
			param.projectNm = this.projectInfo.projectNm;
			param.interviewYear =  this.interviewReq.date.substr(0, 4);
			param.interviewMonth =  this.interviewReq.date.substr(4, 2);
			param.interviewDay =  this.interviewReq.date.substr(6, 2);

			var hour = String(this.interviewReq.hour);
			param.interviewHour = hour.length == 1 ? '0' + hour : hour;

			//console.log('interviewReqEnd REQ', param)

			this.$.httpPost('/api/prj/adm/setTecMberInterviewReq', param)
				.then(() => {
					alert('인터뷰 요청이 완료되었습니다.');
					this.$emit('close', true);
				}).catch(this.$.httpErrorCommon);
		}
	}
	
}
</script>